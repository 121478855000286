.wrapper {
  z-index: 2;
  position: relative;
  height: 90px;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 740px) {
    width: 330px;
  }
}
.nav {
  font-family: 'General Sans';
  font-style: normal;
  text-transform: capitalize;
  display: flex;
  gap: 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #e2e2e2;

  @media (max-width: 1200px) {
    gap: 24px;
  }

  @media (max-width: 740px) {
    display: none;
  }
}
.nav > div {
  cursor: not-allowed;
  opacity: 0.5;
}

.logo_wrapper {
  width: 152px;
}

.logo_wrapper > img {
  cursor: pointer;
}

.button {
  font-family: 'General Sans';
  cursor: pointer;
  width: 152px;
  height: 48px;
  background-color: #efefef;
  color: #171717;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 12px;
  border: none;
}
