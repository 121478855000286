.about_wrapper {
  margin: 0 auto;
  width: 1140px;
  position: relative;
  z-index: 2;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.about_bg {
  position: absolute;
  right: 0;
  top: -140px;
  z-index: 1;

  @media (max-width: 740px) {
    left: -20px;
  }
}

.about_hr {
  width: 60px;
  height: 2px;
  background-color: #e2e2e2;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.about_title {
  width: 555px;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: #ffffff;
  position: relative;
  z-index: 2;

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 32px;
    width: 398px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.about_sub_text {
  margin: 20px 0 90px 0;
  width: 555px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #999999;
  font-family: 'General Sans';
  font-style: normal;
  position: relative;
  z-index: 2;

  @media (max-width: 740px) {
    width: 398px;
    font-size: 18px;
    line-height: 160%;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.about_info_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;

  @media (max-width: 740px) {
    flex-direction: column;
    align-items: start;
    gap: 30px;
    padding-bottom: 120px;
  }
}

.about_info {
}

.about_info_title {
  margin-bottom: 10px;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #e2e2e2;
  position: relative;
  z-index: 5;
}

.about_info_sub_text {
  width: 360px;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  height: 90px;
  line-height: 26px;
  color: #999999;
  margin-bottom: 305px;
  position: relative;
  z-index: 5;

  @media (max-width: 1200px) {
    width: 300px;
    font-size: 17px;
  }

  @media (max-width: 1000px) {
    width: 213px;
  }

  @media (max-width: 740px) {
    width: 100%;
    margin-bottom: 0;
  }
}
