.footer_wrapper {
  background-color: #000000;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 5;
}

.footer_logo_wrapper {
  padding-top: 42px;
  @media (max-width: 740px) {
    padding-top: 0;
  }
}

.footer_logo {
  width: 80px;
  height: 75px;
  color: #ffffff;
}

.footer_nav {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  gap: 40px;
  padding-top: 58px;
  padding-bottom: 46px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #e2e2e2;
  @media (max-width: 740px) {
    width: 400px;
    gap: 18px;
    justify-content: center;
    font-size: 14px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}
.footer_nav > div {
  cursor: not-allowed;
  opacity: 0.5;
}

.footer_hr {
  width: 1140px;
  border: 1px solid #3c3c3c;
  @media (max-width: 1200px) {
    width: 960px;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 700px;
  }
  @media (max-width: 740px) {
    width: 400px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.footer_bottom_text {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 19px;
  padding: 30px 0 35px 0;
  color: #cbd5e1;
  width: 1140px;

  @media (max-width: 1200px) {
    width: 960px;
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 700px;
    margin: 0 auto;
  }
  @media (max-width: 740px) {
    font-size: 13px;
    align-items: start;
    padding: 20px 0;
    line-height: 20px;
    width: 400px;
    white-space: pre-line;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.twitter_link {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #e2e2e2;

  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  @media (max-width: 768px) {
  }
  @media (max-width: 740px) {
    gap: 6px;
  }
  @media (max-width: 429px) {
  }
}
.twitter_link p {
  @media (max-width: 740px) {
    display: none;
  }
  @media (max-width: 429px) {
    display: none;
  }
}
.link_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 740px) {
    height: 40px;
    margin: 13px 0;
  }
  @media (max-width: 429px) {
    display: none;
  }
}
