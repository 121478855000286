.cost_wrapper {
  margin: 0 auto;
  width: 1140px;
  position: relative;
  z-index: 2;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.cost_hr {
  width: 60px;
  height: 2px;
  background-color: #e2e2e2;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.cost_title {
  font-family: 'General Sans';
  font-style: normal;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: #ffffff;

  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 32px;
    width: 398px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.cost_sub_text {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: #999999;
  padding-bottom: 270px;
  position: relative;
  white-space: pre-wrap;
  z-index: 5;
  width: 555px;

  @media (max-width: 740px) {
    width: 398px;
    font-size: 18px;
    line-height: 160%;
    padding-bottom: 160px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.cost_bg_wrapper {
  position: relative;
}

.cost_bg {
  position: absolute;
  top: -562px;
  z-index: 1;
  right: 50%;
  transform: translateX(50%);

  @media (max-width: 740px) {
    top: -170px;
    width: 480px;
    right: 36%;
    height: 336px;
  }
}
