.flexibility_bg_wrapper {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.flexibility_wrapper {
  z-index: 2;
}

.flexibility_hr {
  width: 60px;
  height: 2px;
  background-color: #e2e2e2;
  margin-bottom: 20px;
}

.flexibility_title {
  font-family: 'General Sans';
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  position: relative;
  z-index: 30;
  color: #ffffff;
  @media (max-width: 740px) {
    font-size: 28px;
    line-height: 38px;
  }
}

.flexibility_sub_text {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: #999999;
  padding-bottom: 498px;
  width: 555px;
  position: relative;
  z-index: 3;
  @media (max-width: 740px) {
    font-size: 18px;
    line-height: 160%;
    width: 398px;
    padding-bottom: 395px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.flexibility_bg_wrapper {
  position: relative;
}

.flexibility_bg {
  position: absolute;
  left: 90px;
  top: -247px;
  z-index: 1;
  @media (max-width: 740px) {
    left: 0;
    top: 0;
  }
}
