.App {
  background-color: black;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}

.text_wrapper {
  margin: 0 auto;
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  font-size: 168px;
  letter-spacing: 0.04em;
  line-height: 210px;
  color: #acacac;
  position: relative;
}

@media (max-width: 1225px) {
  .text_wrapper {
    font-size: 138px;
    letter-spacing: 0.04em;
    line-height: 170px;
  }
}

@media (max-width: 1000px) {
  .text_wrapper {
    font-size: 96px;
    line-height: 120px;
  }
  .logo {
    width: 90px;
    height: 84px;
  }
}

@media (max-width: 700px) {
  .text_wrapper {
    font-size: 44px;
    line-height: 55px;
  }
}
