.main_wrapper {
  width: 1140px;
  margin: 0 auto;
  font-weight: 500;
  gap: 20px;
  font-size: 18px;
  line-height: 16px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 338px;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
    padding-bottom: 250px;
  }

  @media (max-width: 740px) {
    gap: 10px;
    width: 400px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.title {
  font-family: 'General Sans';
  font-style: normal;
  white-space: break-spaces;
  color: #ffffff;
  font-weight: 500;
  font-size: 64px;
  margin-bottom: 0;
  margin-top: 125px;
  line-height: 86px;
  text-align: center;

  @media (max-width: 740px) {
    font-size: 28px;
    line-height: 38px;
    margin-top: 75px;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}
.sub_text {
  font-family: 'General Sans';
  font-style: normal;
  text-align: center;
  white-space: break-spaces;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  width: 666px;
  color: #d3d3d3;

  @media (max-width: 740px) {
    font-size: 18px;
    line-height: 160%;
    width: 398px;
  }
  @media (max-width: 740px) {
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.main_button {
  font-family: 'General Sans';
  cursor: pointer;
  width: 206px;
  height: 60px;
  background-color: #efefef;
  color: #171717;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 12px;
  border: none;

  @media (max-width: 740px) {
    width: 168px;
    height: 48px;
  }
}

.main_modal_wrapper {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
