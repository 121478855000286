.accessibility_bg_wrapper {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1140px) {
    width: 960px;
  }

  @media (max-width: 1000px) {
    width: 720px;
  }

  @media (max-width: 740px) {
    width: 400px;
  }

  @media (max-width: 740px) {
    width: 400px;
    display: block;
  }
  @media (max-width: 429px) {
    width: 330px;
  }
}

.accessibility_wrapper {
  z-index: 2;
}

.accessibility_hr {
  width: 60px;
  height: 2px;
  background-color: #e2e2e2;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.accessibility_title {
  font-family: 'General Sans';
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: #ffffff;
  position: relative;
  z-index: 2;
  @media (max-width: 740px) {
    font-size: 28px;
    line-height: 38px;
  }
}

.accessibility_sub_text {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: #999999;
  width: 555px;
  padding-bottom: 270px;
  position: relative;
  z-index: 2;
  @media (max-width: 740px) {
    font-size: 18px;
    line-height: 160%;
    width: 398px;
  }
  @media (max-width: 429px) {
    width: 330px;
    padding-bottom: 140px;
  }
}

.accessibility_bg_wrapper {
  position: relative;
}

.accessibility_bg {
  position: absolute;
  left: 0;
  top: -140px;
  z-index: 1;
  @media (max-width: 740px) {
    left: -40px;
    top: -120px;
  }
}
